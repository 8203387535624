import React, { useEffect, useRef, useState } from 'react';

import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import logo from '../assets/img/header/New/logo-white.png';
import logo1 from '../assets/img/header/logo-dark.png';
import png_prd1 from '../assets/img/header/1.png';
import png_prd2 from '../assets/img/header/2.png';
import png_prd3 from '../assets/img/header/3.png';
import png_prd4 from '../assets/img/header/4.png';
import png_prd5 from '../assets/img/header/5.png';
import png_prd6 from '../assets/img/header/6.png';
import png_prd7 from '../assets/img/header/7.png';
import png_prd8 from '../assets/img/header/8.png';
import png_prd9 from '../assets/img/header/9.png';
import { data } from './mockData'
import { AiOutlineInstagram } from "react-icons/ai"
import { GiSushis } from "react-icons/gi"
import PhotoScroll from "./PhotoScroll"
import PhotoScroll1 from "./PhotoScroll1"

const Hero = () => {
  const handelClick = () => window.open('https://order.nori.ma', '_blank')
  const handelClick2 = () => window.open('tel:0522395555', '_blank')
  const handelClick3 = () => window.open('https://www.instagram.com/norirestaurant', '_blank')
  const list_products = [png_prd1, png_prd2, png_prd3, png_prd4, png_prd5, png_prd6, png_prd7, png_prd8, png_prd9]
  const [index, set_index] = useState(-1);
  var iseUseEffect1 = false;
  // const index1 = useRef(null);
  var ewfqwe = 0;

  const [image_product, set_image_product] = useState('');

  function nextImage1(index) {
    if (list_products?.length != 0) {
      // index1.current = ((index1?.current ?? 0) == list_products?.length - 1) ? 0 : (index1?.current ?? 0) + 1;
      // set_image_product(list_products[index1?.current])
      ewfqwe = (ewfqwe == list_products?.length - 1) ? 0 : ewfqwe + 1
      set_index((index == list_products?.length - 1) ? 0 : index + 1)
      set_image_product(list_products[ewfqwe])
      console.log(index, ewfqwe, list_products[ewfqwe]);
      // set_image_product(list_products[index1?.current])
    }
  }

  useEffect(() => {
    if (!iseUseEffect1) setInterval(() => nextImage1(index), 3000)
    iseUseEffect1 = true
  }, []);

  return (
    <>
      <div className="bg-gray-200">
        <section className="relative bg-menu bg-cover bg-right pb-8 " style={{ borderBottomLeftRadius: '10%', borderBottomRightRadius: '10%', marginBottom: '-4rem' }}>
          <header className='flex justify-center items-center w-full py-6 px-4 top-0 left-0 z-50'>
            <AiOutlineInstagram onClick={handelClick3} className='cursor-pointer text-5xl text-white absolute left-4' style={{ border: '3px solid white', borderRadius: '50%', padding: 3 }} />
            <img src={logo} alt='Logo' style={{ height: 110, width: 'auto' }} />
          </header>

          <div className="max-w-xl text-center sm:text-left mt-20 rounded-b-lg md:pl-10">
            <motion.h1
              variants={fadeIn}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.6 }}
              className="text-4xl text-white font-bold font-Exo sm:text-5xl pb-8"
            >
              NORI EXPERIENCE THE TASTE OF JAPAN WITH EVERY BITE.
            </motion.h1>

            <motion.div
              variants={fadeIn}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, delay: 0.8 }}
              className="mt-0 flex flex-wrap justify-start gap-4 pb-8 sm:p-8 md:p-0"
            >
              <motion.a
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="cursor-pointer block w-full sm:w-auto rounded-full bg-orange-600 hover:bg-orange-400 focus:outline-none focus:ring px-12 py-3 mx-8 md:mx-0 text-sm font-medium text-white shadow"
                onClick={handelClick}
              >
                En Ligne
              </motion.a>

              <motion.a
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="cursor-pointer block w-full sm:w-auto rounded-full bg-orange-600 hover:bg-orange-400 focus:outline-none focus:ring px-12 py-3 mx-8 md:mx-0 text-sm font-medium text-white shadow mt-4 sm:mt-0"
                onClick={handelClick2}
              >
                Appeler
              </motion.a>
            </motion.div>
          </div>
        </section>
      </div>

      <section className="flex flex-col items-center justify-center py-20" style={{ backgroundImage: 'url(/background.png)', backgroundSize: 1500 }}>
        <div className="mx-auto max-w-screen-xl px-4 flex flex-col items-center">
          <GiSushis className="h-20 w-auto mb-8 text-orange-500" />
          <h1 className="text-4xl font-bold text-orange-500 text-center">Quality You can taste</h1>
          <p className="text-white mt-2 max-w-xxl px-4 text-center">
            Découvrez les nouvelles saveurs de Nori Restaurant Chaque saison, notre restaurant vous offre des créations culinaires innovantes et délicieuses, que ce soit des plats gourmands ou rafraîchissants. Ne manquez
          </p>
        </div>
      </section>

      <div className='flex flex-col items-center justify-center' style={{ backgroundImage: 'url(/background.png)', backgroundSize: 1500, paddingTop: 0, paddingBottom: 270 }}>
        <PhotoScroll imageData={data}></PhotoScroll>
      </div>

      <section style={{ backgroundImage: 'url(/background.png)', backgroundSize: 1500, paddingTop: window.innerWidth < 576 ? 50 : 250 }}>
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-center">
          <div className="md:w-4/5 md:pr-8 mb-8 md:mb-0 text-center">
            <h2 className="text-5xl font-bold text-orange-500 mb-4">NORI RESTAURANT</h2>
            <p className="text-gray-200 text-xl mb-8 font-exo">
              <div className='px-5'>
                Nori vous offre un cadre convivial et chaleureux, un service rapide et efficace, une carte extrêmement riche et remplie de produits de premier choix. Nos créations sont réalisées à partir d'ingrédients frais et de qualité, de façon artisanale par nos chefs sushimen.
              </div>
              <div className="relative flex justify-center items-center my-16 mx-auto" style={{ width: 400 }}>
                <div
                  className="duration-700 rounded-lg bg-contain bg-no-repeat ml-2"
                  style={{
                    width: 400,
                    height: 400,
                    // left: 20,
                    bottom: 0,
                    backgroundImage: `url(${logo1})`
                  }}
                >
                  <div
                    className="absolute duration-700 bg-no-repeat bg-contain bg-center mt-12"
                    style={{
                      width: 330,
                      height: 330,
                      left: 45,
                      top: 10,
                      backgroundImage: `url(${image_product})`
                    }}
                  />
                </div>
              </div>
              <span className="px-5 font-exo-bold block mt-4">
                FORMULE MIDI / BOX À PARTAGER / GYOZA / SOUPES / SALADES / KARAAGE / KATSU / DIM SUM / EDAMAME / DUMPLING / TACO / UNI ROLLS / FRESH ROLLS / CALIFORNIA ROLLS / SASHIMI / TATAKI / TARTARES / NIGIRIS / MAKIS / CRISPY ROLLS / SPRING ROLLS / AROMAKIS / HAND ROLLS / CHIRASHIS/YAKITORIS / SAUCE / RIZ VINAIGRÉ
              </span>
            </p>
          </div>

        </div>

        <div className='md:w-4/5 mt-10 mb-20 relative container mx-auto flex flex-col md:flex-row items-center justify-center'>
          <div class="grid gap-4 w-full">

            <div className="h-40 md:h-60 max-w-full relative flex items-center justify-center overflow-hidden shadow-xl rounded-2xl">
              <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110"
                style={{ backgroundImage: `url(https://restau.api.dre.ma/storage/products/March2023/PE24lMvJqz8BsKxMO7md.jpg)` }}
              ></div>
              {/* <h1 className="bottom-0 left-5 absolute text-4xl font-black transition-all duration-500 ease-in-out transform scale-100 text-gray-100 opacity-80">
                Nori Rolls <span className='text-xl'>- Servis par 8 pièces</span>
                <h2 className='text-3xl'>- Servis par 8 pièces</h2>
              </h1> */}
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 h-[100vw] sm:h-[13vw]">

              <div className="max-w-full relative flex items-center justify-center overflow-hidden shadow-xl rounded-2xl">
                <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-125"
                  style={{ backgroundImage: `url(https://restau.api.dre.ma/storage/products/May2023/I8eBw409FV7oidsYZ8T3.jpg)` }}
                ></div>
              </div>

              <div className="max-w-full relative flex items-center justify-center overflow-hidden shadow-xl rounded-2xl">
                <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-125"
                  style={{ backgroundImage: `url(https://restau.api.dre.ma/storage/products/May2023/P558aUHPTspVjmWzloHz.jpg)` }}
                ></div>
              </div>

              <div className="max-w-full relative flex items-center justify-center overflow-hidden shadow-xl rounded-2xl">
                <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-125"
                  style={{ backgroundImage: `url(https://restau.api.dre.ma/storage/products/May2023/8sFSt0J3MFqOjyJnPiAQ.jpg)` }}
                ></div>
              </div>

              <div className="max-w-full relative flex items-center justify-center overflow-hidden shadow-xl rounded-2xl">
                <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-125"
                  style={{ backgroundImage: `url(https://restau.api.dre.ma/storage/products/March2023/YEIxB7phICQZ8aLRMp7H.jpg)` }}
                ></div>
              </div>

              <div className="max-w-full relative flex items-center justify-center overflow-hidden shadow-xl rounded-2xl">
                <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-125"
                  style={{ backgroundImage: `url(https://restau.api.dre.ma/storage/products/May2023/jT4ct7XlOl3QW4qLqKQt.jpg)` }}
                ></div>
              </div>

              <div className="max-w-full relative flex items-center justify-center overflow-hidden shadow-xl rounded-2xl">
                <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-125"
                  style={{ backgroundImage: `url(https://restau.api.dre.ma/storage/products/May2023/dYv92GiU4xTTRJUIuxrm.jpg)` }}
                ></div>
              </div>

            </div>
          </div>
        </div>

        {/* <div className='mt-10 mb-20 mx-auto relative flex items-center justify-center'>
          <div className="grid grid-flow-row-dense grid-cols-3 grid-rows-3 ...">
            <div className="row-span-2">
              <div className="relative flex items-center justify-center m-3 overflow-hidden shadow-xl rounded-tl-2xl">
                <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover bg-hero hover:scale-125"></div>
                <h1 className="absolute text-5xl font-black transition-all duration-500 ease-in-out transform scale-150 text-gray-50 opacity-60">CAR</h1>
              </div>
            </div>
            <div>
              <div className="relative flex items-center justify-center m-3 overflow-hidden shadow-xl w-60 h-60 rounded-tl-2xl">
                <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover bg-hero hover:scale-125"></div>
                <h1 className="absolute text-5xl font-black transition-all duration-500 ease-in-out transform scale-150 text-gray-50 opacity-60">CAR</h1>
              </div>
            </div>
            <div>
              <div className="relative flex items-center justify-center m-3 overflow-hidden shadow-xl w-60 h-60 rounded-tl-2xl">
                <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover bg-hero hover:scale-125"></div>
                <h1 className="absolute text-5xl font-black transition-all duration-500 ease-in-out transform scale-150 text-gray-50 opacity-60">CAR</h1>
              </div>
            </div>
            <div>
              <div className="relative flex items-center justify-center m-3 overflow-hidden shadow-xl w-60 h-60 rounded-tl-2xl">
                <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover bg-hero hover:scale-125"></div>
                <h1 className="absolute text-5xl font-black transition-all duration-500 ease-in-out transform scale-150 text-gray-50 opacity-60">CAR</h1>
              </div>
            </div>
            <div>
              <div className="relative flex items-center justify-center m-3 overflow-hidden shadow-xl w-60 h-60 rounded-tl-2xl">
                <div className="absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover bg-hero hover:scale-125"></div>
                <h1 className="absolute text-5xl font-black transition-all duration-500 ease-in-out transform scale-150 text-gray-50 opacity-60">CAR</h1>
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-hero bg-cover bg-right h-[400px]">
        </div>
      </section >

    </>
  );
};

export default Hero;






